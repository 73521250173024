<template>
  <ion-page>
    <ion-content fullscreen>
      <ion-grid>
        <ion-row class="l-photo">
          <ion-col size="12">
            <ion-text color="danger">
              <h5>{{$t('surveyDescriptionText6')}}</h5>
            </ion-text>
          </ion-col>
          <template v-if="checkList.indexOf('1') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo1"
              :src="photo1"
              @ionError="photo1 = '/assets/images/common/no-image.png'"
              @click="handlePhoto1()"
            />
            <ion-button v-if="!photo1" @click="handlePhoto1()">
              <span>{{$t('labelPhoto1')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo1" class="clear" @click="removePhoto1()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('2') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo2"
              :src="photo2"
              @ionError="photo2 = '/assets/images/common/no-image.png'"
              @click="handlePhoto2()"
            />
            <ion-button v-if="!photo2" @click="handlePhoto2()">
              <span>{{$t('labelPhoto2')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo2" class="clear" @click="removePhoto2()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('3') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo3"
              :src="photo3"
              @ionError="photo3 = '/assets/images/common/no-image.png'"
              @click="handlePhoto3()"
            />
            <ion-button v-if="!photo3" @click="handlePhoto3()">
              <span>{{$t('labelPhoto3')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo3" class="clear" @click="removePhoto3()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('4') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo4"
              :src="photo4"
              @ionError="photo4 = '/assets/images/common/no-image.png'"
              @click="handlePhoto4()"
            />
            <ion-button v-if="!photo4" @click="handlePhoto4()">
              <span>{{$t('labelPhoto4')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo4" class="clear" @click="removePhoto4()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('5') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo5"
              :src="photo5"
              @ionError="photo5 = '/assets/images/common/no-image.png'"
              @click="handlePhoto5()"
            />
            <ion-button v-if="!photo5" @click="handlePhoto5()">
              <span>{{$t('labelPhoto5')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo5" class="clear" @click="removePhoto5()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('6') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo6"
              :src="photo6"
              @ionError="photo6 = '/assets/images/common/no-image.png'"
              @click="handlePhoto6()"
            />
            <ion-button v-if="!photo6" @click="handlePhoto6()">
              <span>{{$t('labelPhoto6')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo6" class="clear" @click="removePhoto6()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('7') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo7"
              :src="photo7"
              @ionError="photo7 = '/assets/images/common/no-image.png'"
              @click="handlePhoto7()"
            />
            <ion-button v-if="!photo7" @click="handlePhoto7()">
              <span>{{$t('labelPhoto7')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo7" class="clear" @click="removePhoto7()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
          <template v-if="checkList.indexOf('8') > -1">
          <ion-col size="6">
            <ion-img
              v-if="photo8"
              :src="photo8"
              @ionError="photo8 = '/assets/images/common/no-image.png'"
              @click="handlePhoto8()"
            />
            <ion-button v-if="!photo8" @click="handlePhoto8()">
              <span>{{$t('labelPhoto8')}}</span>
              <ion-icon :icon="cameraOutline"></ion-icon>
            </ion-button>
            <ion-button v-if="photo8" class="clear" @click="removePhoto8()">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
          </ion-col>
          </template>
        </ion-row>
      </ion-grid>
      <ion-button color="primary" expand="full" @click="handleApply">{{$t('buttonLabelNext')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage,
  IonContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  // toastController,
  loadingController,
  // useIonRouter
} from '@ionic/vue'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { addFileData, listData, updateData } from '@/api/board/common'
import { DataURIToBlob } from '@/utils/commonUtils'

export default {
  name: "photos",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonButton
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    let orderNum = ref(undefined)
    let photos = ref([])
    let photo1 = ref(undefined)
    let photo2 = ref(undefined)
    let photo3 = ref(undefined)
    let photo4 = ref(undefined)
    let photo5 = ref(undefined)
    let photo6 = ref(undefined)
    let photo7 = ref(undefined)
    let photo8 = ref(undefined)
    let photoFilename = ref([])

    let basic = ref(undefined)
    let cpu = ref(undefined)
    let ram = ref(undefined)
		let	vga = ref(undefined)
		let	lcd_size = ref(undefined)
		let	deduct_1 = ref(undefined)
		let	deduct_2 = ref(undefined)
		let	deduct_3 = ref(undefined)
		let	deduct_4 = ref(undefined)
		let	deduct_5 = ref(undefined)
		let	special = ref(undefined)

    let total_price = ref(undefined)

    const checkList = ref([])

    onMounted(async () => {
      await getOrderNum()
      await getOrderdetail()
    })

    const route = useRoute()
    // const router = useIonRouter()

    const imgCompressOptions = ref({
      maxSizeMB: 100,
      maxWidthOrHeight: 200,
      useWebWorker: true,
    })

    const handlePhoto1 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo1.value = image.dataUrl
    }

    const removePhoto1 = async () => {
      photo1.value = undefined
    }

    const handlePhoto2 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo2.value = image.dataUrl
    }

    const removePhoto2 = async () => {
      photo2.value = undefined
    }

    const handlePhoto3 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo3.value = image.dataUrl
    }

    const removePhoto3 = async () => {
      photo3.value = undefined
    }

    const handlePhoto4 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo4.value = image.dataUrl
    }

    const removePhoto4 = async () => {
      photo4.value = undefined
    }

    const handlePhoto5 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo5.value = image.dataUrl
    }

    const removePhoto5 = async () => {
      photo5.value = undefined
    }

    const handlePhoto6 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo6.value = image.dataUrl
    }

    const removePhoto6 = async () => {
      photo6.value = undefined
    }
    const handlePhoto7 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo7.value = image.dataUrl
    }

    const removePhoto7 = async () => {
      photo7.value = undefined
    }

    const handlePhoto8 = async () => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo8.value = image.dataUrl
    }

    const removePhoto8 = async () => {
      photo8.value = undefined
    }

    const getOrderNum = async () => {
      const apiUrl = `/api/notebook/order/ordernum/${route.params.num}`
      orderNum.value = await listData(apiUrl)
    }

    const getOrderdetail = async () => {
      let apiUrl = `/api/notebook/order/orderdetail/${Number(route.params.num)}`
      await listData(apiUrl).then(async (response) => {
        basic.value = Number(response.basic)
        cpu.value = Number(response.cpu)
        ram.value = Number(response.ram)
        vga.value = Number(response.vga)
        lcd_size.value = Number(response.lcd_size)
        deduct_1.value = Number(response.deduct_1)
        deduct_2.value = Number(response.deduct_2)
        deduct_3.value = Number(response.deduct_3)
        deduct_4.value = Number(response.deduct_4)
        deduct_5.value = Number(response.deduct_5)
        special.value = Number(response.special)
        
        if((response.message_text != '' || response.message_text !== undefined) && response.message_text !==null) {
          checkList.value = response.message_text.split(',')
        } else {
          checkList.value = '1,2,3,4,5,6,7,8'.split(',')
        }
        
      })
    }

    const setTotalPrice = () => {
      total_price.value = basic.value + 
                                  cpu.value +
                                  ram.value +
                                  vga.value +
                                  lcd_size.value +
                                  deduct_1.value + 
                                  deduct_2.value + 
                                  deduct_3.value + 
                                  deduct_4.value + 
                                  deduct_5.value + 
                                  special.value
    
    }

    const postOrderPicture = async () => {
      showLoading(true)
      // const apiUrl = '/api/notebook/order/picture'
      const apiUrl = '/api/order/picture'

      if (photo1.value) {
        // photos.value.push(photo1)
        photos.value[0] = photo1
      } 
      
      if (photo2.value) {
        // photos.value.push(photo2)
        photos.value[1] = photo2
      }
      
      if (photo3.value) {
        // photos.value.push(photo3)
        photos.value[2] = photo3
      } 
      
      if (photo4.value) {
        // photos.value.push(photo4)
        photos.value[3] = photo4
      }

      if (photo5.value) {
        // photos.value.push(photo5)
        photos.value[4] = photo5
      } 
      
      if (photo6.value) {
        // photos.value.push(photo6)
        photos.value[5] = photo6
      }
      
      if (photo7.value) {
        // photos.value.push(photo7)
        photos.value[6] = photo7
      }
      
      if (photo8.value) {
        // photos.value.push(photo8)
        photos.value[7] = photo8
      }

      let formData = new FormData()
      // photos.value.forEach((e, k) => {
      //   const file = DataURIToBlob(e)
      //   const fileOfBlob = new File([file], `notebook_image_${k}.jpg`, {type:'image/jpeg'})
      //   formData.append('file', fileOfBlob)
      //   // photoFilename.value.push(`https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/notebook/${orderNum.value}/notebook_image_${k}.jpg`)
      //   photoFilename.value.push(`https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum.value}/notebook_image_${k}.jpg`)
      // })

      for (let i = 1; i <= 8; i++) {
        if (photos.value[i-1] !== undefined) { 
          const file = DataURIToBlob(photos.value[i-1])
          const fileOfBlob = new File([file], `notebook_image_${i}.jpg`, {type:'image/jpeg'})
          formData.append('file', fileOfBlob)
          photoFilename.value[i-1] = (`https://s3-ap-southeast-1.amazonaws.com/vnpicture.qcheck.kr/hardware/${orderNum.value}/notebook_image_${i}.jpg`)
        }
      }

      formData.append('ord_number', orderNum.value)
      await addFileData(apiUrl, formData).then((response) => {
        if(response.result === 'success') {
          putPhotos()
        }
      })
      showLoading(false)
    }

    const putPhotos = async () => {
      const apiUrl = `/api/notebook/order/`
      let payload = ref({
        num: Number(route.params.num),
        total_price: Number(total_price.value),
        picture_1: photoFilename.value[0],
        picture_2: photoFilename.value[1],
        picture_3: photoFilename.value[2],
        picture_4: photoFilename.value[3],
        picture_5: photoFilename.value[4],
        picture_6: photoFilename.value[5],
        picture_7: photoFilename.value[6],
        picture_8: photoFilename.value[7],
				is_confirm: 'N'
      })
      await updateData(apiUrl, payload.value).then(response => {
        if (response) {
          // router.push({ path: '/checkup/check' })
          location.href = '/checkup/check'
        }
      })
    } 

    const handleApply = () => {
      setTotalPrice()
      postOrderPicture()
      /* if (checkValidate()) {
        postOrderPicture()
      }  */
    }

    /* const checkValidate = () => {
      let message
      if (!photo1.value) {
        message = t('messagePhotoText1')
        showMessage(message)
        return false
      } else if (!photo2.value) {
        message = t('messagePhotoText2')
        showMessage(message)
        return false
      } else if (!photo3.value) {
        message = t('messagePhotoText3')
        showMessage(message)
        return false
      } else if (!photo4.value) {
        message = t('messagePhotoText4')
        showMessage(message)
        return false
      } else if (!photo5.value) {
        message = t('messagePhotoText5')
        showMessage(message)
        return false
      } else if (!photo6.value) {
        message = t('messagePhotoText6')
        showMessage(message)
        return false
      } else if (!photo7.value) {
        message = t('messagePhotoText7')
        showMessage(message)
        return false
      } else if (!photo8.value) {
        message = t('messagePhotoText8')
        showMessage(message)
        return false
      } else {
        return true
      }
    } */

    /* const showMessage = (message) => {
      toastController.create({
        message: message,
        duration: 3000,
        color: 'danger',
        cssClass: 'custom-toast',
        buttons: [
          {
            text: t('buttonLabelClose'),
            role: 'cancel'
          }
        ],
      }).then(toast => toast.present())
    } */

    const showLoading = async (type) => {
      const loading = await loadingController.create({
        message: t('messageLoadingText'),
        // duration: 3000,
        cssClass: 'custom-loading'
      })
      if (type) {
        loading.present()
      } else {
        loading.dismiss()
      }
    }

    return {
      cameraOutline, 
      trash,
      photos,
      photo1,
      photo2,
      photo3,
      photo4,
      photo5,
      photo6,
      photo7,
      photo8,
      imgCompressOptions,
      handlePhoto1,
      removePhoto1,
      handlePhoto2,
      removePhoto2,
      handlePhoto3,
      removePhoto3,
      handlePhoto4,
      removePhoto4,
      handlePhoto5,
      removePhoto5,
      handlePhoto6,
      removePhoto6,
      handlePhoto7,
      removePhoto7,
      handlePhoto8,
      removePhoto8,
      handleApply,
      checkList
    }
  },
}
</script>

<style scoped>
:deep ion-card {
  box-shadow: none;
}
:deep ion-chip {
  margin-inline: 0;
}
:deep ion-card-header,
ion-card-content {
  padding: 0 10px;
}
:deep ion-card-content {
  padding-top: 10px;
}
.l-photo :deep ion-col {
  text-align: center;
  position: relative;
}
.l-photo :deep ion-button,
.l-photo .photo {
  width: 150px;
  height: 150px;
  --background: transparent;
  --background-focused: transparent;
  --background-hover: rgba(0, 0, 0, 0.3);
  border: 2px solid #E84EC8;;
  border-radius: 5px;
  font-size: 1em;
  --color: #E84EC8;;
}

.l-photo .photo {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.l-photo :deep ion-img {
  position: absolute;
  width: 145px;
  height: 145px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.l-photo .clear {
  --color: rgb(209, 207, 207);
  font-size: 30px;
}

@media (max-width: 320px) {
  .l-photo {
    flex-direction: column;
  }
  .l-photo :deep ion-col,
  .l-photo .photo {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
  }
}
</style>
